html {
	height: 100%;
}

body {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 300;
	color: #ddd;
	background-color: #000;
	font-size: 12px;
	line-height: 18px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;
}

input:focus {
    outline: none;
}

select {

}

h1 {
	font-size: 30px;
	font-weight: 400;
	margin: 0;
}

h2 {
	font-size: 25px;
	font-weight: 400;
}

h3 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	margin: 0px 0 15px 0;
}

p {
	margin: 0px 0 8px 0;
}

a {
	color: #eee;
}

a:visited {
	color: #eee;
}

a:hover, a:focus, a:visited:hover {
	color: #fff;
}

b {
	font-weight: 500;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
}
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255,255,255,.5);
	width: 10px;
}
::-webkit-scrollbar-track {
    background-color: rgba(255,255,255,.2);
    border-radius: 2px;
}

/* override bootstrap defaults: */

.navbar-default {
	background-color: #444444;
	border-color: #444444;
}
.navbar-default .navbar-brand {
	color: #ecf0f1;
	font-size: 20px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	padding-left: 30px;
	padding-right: 50px;
}
.navbar-default .navbar-brand .beta {
	font-size: 10px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	letter-spacing: 0.05em;
	top: 7px;
	margin-left: 2px;
	position: absolute;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #ffffff;
}
.navbar-default .navbar-text {
  color: #ecf0f1;
}
.navbar-default .navbar-nav > li > a {
  color: #ecf0f1;
  transition: all .2s ease-in-out;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #ffffff;
	background-color: #5d5c5c;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #5d5c5c;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #ffffff;
  background-color: #5d5c5c;
}
.navbar-default .navbar-toggle {
  // border-color: #5d5c5c;
  border: none;
  margin-right: 5px;
  opacity: 0.8;
  transition: all .2s ease-in-out;

  &:active, &:hover {
    opacity: 1;
  }
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ecf0f1;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: transparent;
}
.navbar-default .navbar-link {
  color: #ecf0f1;
}
.navbar-default .navbar-link:hover {
  color: #ffffff;
}

/* prevent logo hover background: */
.navbar-right > li > a:hover, .navbar-right > li > a:focus {
	/* background-color: transparent !important; */
}

#logo_ml {
	padding: 0;
	margin-right: 15px;
	position: relative;
	opacity: 0.85;
	width: 200px;
	height: 50px;

  &:hover {
    opacity: 1;
  }

  a {
    height: 50px;
    width: 100%;
  }

  .text {
    display: block;
    clear: left;
    position: absolute;
    top: 15px;
    right: 70px;
    font-size: 10px;
    letter-spacing: 0.05em;
    color: white;
  }

  img {
    margin: 0;
    width: 50px;
    height: 50px;
    pointer-events: auto;
    position: absolute;
    top: -3px;
    right: 10px;
  }

}

#background {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: -10000;
	width: 100%;
	height: 100%;
	text-align: left;
	overflow: hidden;
	pointer-events: none;

	background: #000000; /* Old browsers */
	background: -moz-linear-gradient(top,  #000000 0%, #333333 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#000000), color-stop(100%,#333333)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #000000 0%,#333333 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #000000 0%,#333333 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #000000 0%,#333333 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #000000 0%,#333333 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#333333',GradientType=0 ); /* IE6-9 */
}

#container {
	z-index: -1;
	position: fixed;
	top: 0px;
	left: 0px;
}

#top-overlay {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 250px;
	overflow: hidden;
	pointer-events: none;

  .background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 250px;
    opacity: 0.8;
    background: url('../assets/img/gradient-top-left.png') no-repeat;
    pointer-events: none;
  }

}

#nationality {
	position: absolute;
	top: 130px;
	left: 30px;
	font-size: 15px;
	font-weight: 300;
	line-height: 22px;
	color: white;
}

.country_dropdown_container {
	margin: 8px;
	pointer-events: none;
	opacity: 0.5;

  .filled {
    color: #fff;
  }

  .glyphicon-search {
    position: absolute;
    top: 9px;
    left: 8px;
    font-size: 14px;
    pointer-events: none;
  }

  .cancel {
    background: url('../assets/img/close.svg') transparent;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    right: 2px;
    top: 1px;
    cursor: pointer;
    pointer-events: auto;
    display: none;
  }

  .caret {
    margin-top: -1px;
    margin-left: -20px;
    pointer-events: none;
    display: none;
    transition: all .2s ease-in-out;
  }

  &:hover, &:focus {
    .country_dropdown {
      background-color: #222;
      color: #fff;
    }
  }

  .country_dropdown {
    width: 200px;
    // border: 1px solid #aaa;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    background-color: #333;
    color: #ddd;
    padding: 0.25em 2.1em 0.4em 2.1em;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    pointer-events: auto;
    transition: all .2s ease-in-out;
    -webkit-appearance: none;
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover, &:focus {
      // border: 1px solid #999;
      background-color: #222;
      color: #fff;
    }

  }
}

#destination_country_dropdown_container {
	margin-left: 0;
}

#arrow_right {
	float: left;
	top: 18px;
	margin: 0 7px 0 0;
}

.immybox_results {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 20em;
  color: #dddddd;
  background-color: #222222;
  // border: 1px solid #777777;
  border-top: none;
  z-index: 9001;
  max-height: 420px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 3px 20px 5px rgba(0,0,0,0.6);

  p.immybox_moreinfo,p.immybox_noresults {
    color:#ccc;
    font-style: italic;
    padding:0 1em;
    margin:0;
  }
  ul {
    list-style-type:none;
    margin:0;
    margin-top: -1px;
    padding:0;
  }
  ul li {
    -o-user-select:none;
    -moz-user-select:none;
    -khtml-user-select:none;
    -webkit-user-select:none;
    user-select:none;
    padding: 0.75em 0.75em;
  }
  ul li.immybox_choice {
    cursor:pointer;
    padding: 0.5em 0.75em;
  }

  ul li.immybox_choice .sovereignty {
    color: #999;
  }

  ul li.immybox_choice.active {
    background-color:#f1f1f1;
    background-color: #444444;
    color: white;
  }
  ul li.immybox_choice .highlight {
    text-decoration:underline;
  }

}

.nav {
  .open {
    a:focus {
      border: 1px solid #aaa;
    }
  }

  .caret {
    margin: -1px 5px 0 3px;
    transition: all .2s ease-in-out;
  }
}

#map_mode {
  margin: 8px 0 0 5px;
  -webkit-font-smoothing: antialiased;
}

.navbar-default .dropdown-toggle {
  // border: 1px solid #aaa;
  border: 1px solid transparent !important;
  padding: 0.5em 0.5em 0.5em 0.7em;
  border-radius: 4px;
  color: #ddd;
  background-color: #333;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  pointer-events: auto;
  z-index: 1000;
  transition: all .2s ease-in-out;

  &:hover, &:focus {
    color: #fff;
    // background-color: #444;
    background-color: #222 !important;

    .dropdown-label {
      color: #fff;
    }
  }

  .dropdown-label {
    display: inline-block;
    width: 125px;
    color: #ddd;
    transition: all .2s ease-in-out;
  }

}

.navbar-default .navbar-nav .open {
  .dropdown-menu {
    margin-top: -5px;
    padding: 0px;
    color: #dddddd;
    background-color: #222222;
    // border: 1px solid #777777;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    z-index: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 3px 20px 5px rgba(0,0,0,0.6);

    > li {
      padding: 0;
    }

    > li:first-child {
      padding-top: 5px;
    }

    > li > a {
      color: #ecf0f1;
      background-color: #222222;
      padding: 0.5em 0.75em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }
    > li > a:hover, > li > a:focus {
      color: #ffffff;
      background-color: #444444;
      border: none;
    }
    > .active > a, > .active > a:hover, > .active > a:focus {
      color: #ffffff;
      // background-color: #5d5c5c;
      // background-color: #444444;
      background-color: #222;
    }

  }
}

#travelscope {
	position: fixed;
	top: 70px;
	left: 30px;
	margin: 0;
	font-size: 24px; /* 15px */
	font-weight: 400;
	line-height: 34px; /* 22px */
	pointer-events: none;
	width: 100%;
	max-width: 650px;
	-webkit-font-smoothing: antialiased;
	display: none;

  .visa-title {
    font-weight: bold;
  }

  .notes {
    font-size: 12px;
    line-height: 18px;
    margin: 10px 0 0 0;
  }

  .glyphicon-arrow-right {
    display: block;
    float: left;
    padding-right: 9px;
    font-size: 0.75em;
    padding-top: 7px;
  }

  b {
    white-space: nowrap;
    font-weight: 600;
  }

}

#button_country_list {
	position: fixed;
	top: 75px;
	right: 30px;
	z-index: 100;
	width: 212px;
	text-align: left;
	display: none;
  background-color: #333333;
  border: none;
  color: #dddddd;
  padding: 0.75em 1em;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  transition: all .2s ease-in-out;

  &:active {
    outline: none;
  }

  &:hover {
    background-color: #444;
  }

  .caret {
    position: absolute;
    right: 10px;
    top: 16px;
    transition: all .2s ease-in-out;
  }

}

#country_list {
	position: absolute;
	top: 115px;
	right: 30px;
	width: 210px;
	height: calc(100% - 210px);
	pointer-events: auto;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin: 0;
	padding: 0;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;

	// background: #000000; /* Old browsers */
	// background: -moz-linear-gradient(top,  #000000 0%, #333333 100%); /* FF3.6+ */
	// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#000000), color-stop(100%,#333333)); /* Chrome,Safari4+ */
	// background: -webkit-linear-gradient(top,  #000000 0%,#333333 100%); /* Chrome10+,Safari5.1+ */
	// background: -o-linear-gradient(top,  #000000 0%,#333333 100%); /* Opera 11.10+ */
	// background: -ms-linear-gradient(top,  #000000 0%,#333333 100%); /* IE10+ */
	// background: linear-gradient(to bottom,  rgba(0,0,0,0.75) -20%, rgba(50,50,50,0.75) 120%); /* W3C */
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#333333',GradientType=0 ); /* IE6-9 */

  li {
    list-style-type: none;
    margin: 0;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    color: #bbb;
    cursor: pointer;
    pointer-events: auto;
  }

  li:hover, li.selected, li.hover {
    color: #fff;
  }

  li .container {
    position: relative;
    width: 100%;
  }

  li .container.is-sovereignty {
    border: 1px solid red;
  }

  li .box {
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px 5px 0 0;
    background: transparent;
    position: absolute;
    left: 0px;
  }

  li .number {
    display: block;
    margin: 0 5px 0 0;
    position: absolute;
    left: 15px;
    text-align: right;
    width: 25px;
  }

  li .text {
    display: block;
    padding-right: 0px;
    margin-left: 35px;
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.numberhidden .text {
    margin-left: 5px;
  }

  &.numberhidden .number {
    display: none;
  }

  &.narrownumbers .text {
    margin-left: 5px;
    width: 140px;
  }

  &.narrownumbers .number {
    display: block;
    width: 30px;
    left: 160px;
  }

  &.widenumbers .text {
    margin-left: 5px;
    width: 90px;
  }

  &.widenumbers .number {
    display: block;
    width: 80px;
    left: 110px;
  }

}

.superscript {
	vertical-align: super;
	font-size: 0.7em;
	padding: 0 2px;
}

.last_update {
	position: fixed;
	bottom: 30px;
	left: 30px;
	font-size: 10px;
	letter-spacing: 0.03em;
	color: #999;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	// display: none;
}

.legend {
	position: fixed;
	bottom: 55px;
	left: 30px;
	font-size: 10px;
	letter-spacing: 0.03em;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	pointer-events: none;
	display: none;

  .range {
    width: 220px;
    margin-top: 25px;
    margin-bottom: 30px;
    clear: left;
  }

  .range .box {
    width: 100%;
    height: 10px;
    margin-bottom: 3px;
  }

  .range .min {
    float: left;
    width: 33%;
  }

  .range .rangelabel {
    float: left;
    text-align: center;
    width: 34%;
  }

  .range .max {
    float: right;
    text-align: right;
    width: 33%;
  }

  .colors .box {
    width: 10px;
    height: 10px;
    margin: 4px 6px 6px 0;
    float: left;
    clear: left;
  }

  .colors .text {
    float: left;
  }

  .info_visa_free {
    clear: left;
    margin-bottom: 15px;
  }

}

#loading {
	position: fixed;
	font-size: 18px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	color: white;
	background-color: #333;
	width: auto;
  text-align: center;
	padding: 0.7em 0.7em 0.7em 0.7em;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	opacity: 0.85;
	box-shadow: 0px 3px 40px 5px rgba(0,0,0,0.4);

  .details {
    font-size: 12px;
    line-height: 18px;
    margin: 15px 0 5px 0;
    width: 100%;
  }
}

#social {
  position: absolute;
  right: 150px;
  bottom: 30px;
  font-size: 1.4em;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  display: none;

  i {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    color: white;
    width: 2.2em;
    height: 2.2em;
    text-align: center;
    padding-top: 0.5em;
    cursor: pointer;
    opacity: 0.75;
    transition: all .2s ease-in-out;

    &:hover {
      white-space: normal;
      opacity: 1;
    }

    &.icon-facebook {
    }

    &.icon-twitter-bird {
      font-size: 0.8em;
      padding-top: 0.1em;
    }

  }

}

#keyboardhint {
	position: fixed;
	top: 100px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	color: white;
	width: auto;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

#country-tooltip {
	position: absolute;
	font-size: 15px;
	color: white;
	background-color: #333;
	width: auto;
	pointer-events: none;
	padding: 0.6em 0.7em 0.6em 0.7em;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	opacity: 0.9;
	display: none;
	z-index: 500;
	box-shadow: 0px 3px 40px 5px rgba(0,0,0,0.4);
  // -webkit-font-smoothing: antialiased;

  .title {
  }

  .details {
    font-size: 12px;
    line-height: 18px;
    max-width: 300px;
    margin-top: 5px;
    display: none;
    word-wrap: break-word;
  }

  .visa-title {
    font-weight: 400;
  }

  .notes {
    font-size: 10px;
    letter-spacing: 0.05em;
    margin-top: 5px;
  }

}

.panel {
	position: absolute;
	font-size: 15px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	color: #ddd;
	background-color: #333;
	width: 750px;
	height: calc(100% - 290px);
	left: 500px;
	top: 100px;
	padding-bottom: 80px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	opacity: 0.9;
	z-index: 100;
	display: none;
	box-shadow: 0px 3px 40px 5px rgba(0,0,0,0.4);

  .inside {
    height: 100%;
    margin: 12px 18px 22px 18px;
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .inside .section {
    margin: 0 0 30px 0;
  }

  .title {
    margin: 18px 18px 10px 18px;
    color: #fff;
  }

  .panel-close {
    background: url('../assets/img/close.svg');
    width: 50px;
    height: 50px;
    color: white;
    float: right;
    position: absolute;
    top: 3px;
    right: 0px;
    opacity: 0.85;
    cursor: pointer;
    padding: 15px;
  }

  .panel-close:hover {
    opacity: 1;
  }

  .details {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
  }

  a {
    text-decoration: underline;
    font-weight: 400;
  }
}

.slider {
	clear: left;
	margin: -5px 0 15px 0;
	width: 220px;
}

.ui-widget.ui-widget-content {
  border: none;
}

.ui-slider-horizontal {
	height: 12px;
	background-color: #555;
	border: none;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}

.ui-slider {
  .ui-slider-handle {
  	width: 12px;
  	height: 12px;
  	cursor: pointer;
  	border-radius: 6px;
  	-moz-border-radius: 6px;
  	-webkit-border-radius: 6px;
  	top: 0px; /* -4px */
  	margin-left: -5px; /* -4px */
  	background-color: #ddd;
  	border: none;
    transition: background-color .2s ease-in-out;

    &:focus, &:hover {
      background-color: #fff;
      border: none;
      outline: none;
    }
  }

  &:focus, &:hover {
    .ui-slider-handle {
      background-color: #fff;
      border: none;
      outline: none;
    }
  }
}

#slider_zoom {
	position: absolute;
	bottom: 15px;
	left: 400px;
	width: 150px;
	display: none;
}

#view_switch {
	position: fixed;
	bottom: 35px;
	right: 30px;
	display: none;

  .icon-worldmap-flat {
    display: block;
    width: 25px;
    height: 16px;
    background-image: url(../assets/img/icon-worldmap_flat_25px_white.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0px;
    margin-right: -1px;
    margin-top: 2px;
    margin-bottom: 2px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    /* HD/Retina CSS */
    @media
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and ( min--moz-device-pixel-ratio: 1.25),
    only screen and ( -o-min-device-pixel-ratio: 1.25/1),
    only screen and ( min-device-pixel-ratio: 1.25),
    only screen and ( min-resolution: 200dpi),
    only screen and ( min-resolution: 1.25dppx)
    {
      background-image: url(/assets/img/icon-worldmap_flat_50px_white.png);
    }
  }
}

.btn {
  border: none;
  background-color: #555;
  color: #ddd;
  transition: all .2s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover, &:active, &.active {
    border: none;
    background-color: #666;
    color: #fff;
    box-shadow: none;
  }

}

.glyphicon-globe {
	padding: 0px 5px;
}

#logterminal {
	top: 250px !important;
	left: 20px !important;
	width: 75% !important;
	height: calc(100% - 300px) !important;
}

.error {
	color: red;
}
