.tipsy { padding: 5px; font-size: 10px; letter-spacing: 0.05em; position: absolute; z-index: 100000; }
  .tipsy-inner { padding: 5px 8px 4px 8px; background-color: #444; color: white; max-width: 200px; text-align: center; border-radius: 3px; -moz-border-radius:3px; -webkit-border-radius:3px; }
  .tipsy-arrow { position: absolute; background: url('../assets/img/tipsy.gif') no-repeat top left; width: 9px; height: 5px; }
  .tipsy-n .tipsy-arrow { top: 0; left: 50%; margin-left: -4px; }
    .tipsy-nw .tipsy-arrow { top: 0; left: 10px; }
    .tipsy-ne .tipsy-arrow { top: 0; right: 10px; }
  .tipsy-s .tipsy-arrow { bottom: 0; left: 50%; margin-left: -4px; background-position: bottom left; }
    .tipsy-sw .tipsy-arrow { bottom: 0; left: 10px; background-position: bottom left; }
    .tipsy-se .tipsy-arrow { bottom: 0; right: 10px; background-position: bottom left; }
  .tipsy-e .tipsy-arrow { top: 50%; margin-top: -4px; right: 0; width: 5px; height: 9px; background-position: top right; }
  .tipsy-w .tipsy-arrow { top: 50%; margin-top: -4px; left: 0; width: 5px; height: 9px; }
